body {
    background-color: $c-beige;
    font-family: 'GraphikLight';
    color: black;
}
// #root {
//     max-width: 820px;
//     margin: auto;
// }
h1 {
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: $c-gold;
}
a {
    &:hover {
        text-decoration: none;
    }
}
.header, .footer {
    background-color: $c-beige;
    padding: 40px 20px;
    @include media-breakpoint-up(md) {
        padding: 70px 30px;
    }
}
.header {
    .container {
        @media(min-width: 1920px) {
            max-width: 1070px;
            padding: 0;
        }
    }
    img {
        width: 120px;
        margin: auto;
        @include media-breakpoint-up(md) {
            width: 180px;
        }
        @include media-breakpoint-up(lg) {
            width: 250px;
        }
    }
    @include media-breakpoint-up(md) {
        padding-left: 0;
        padding-top: 50px;
    }
    @include media-breakpoint-up(lg) {
        padding: 100px 0;
    }    
}

.page-content {
    width: 100%;
    min-height: 400px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @include media-breakpoint-up(md) {
        min-height: calc(100vh - 400px);
    }
    h1 {
        margin-bottom: 40px;
        color: cadetblue;
    }
    .container {
        padding: 0 20px 0 50px;
        margin: auto;
        @include media-breakpoint-up(md) {
            padding: 0;
        }
        @include media-breakpoint-up(lg) {
            position: relative;
        }
        @media(min-width: 1320px) {
            max-width: 1070px;
        }
    }
}

.ebusinesscard-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @include media-breakpoint-up(md) {
        padding-left: 80px;
        padding-right: 20px;
    }
    .container {
        @include media-breakpoint-up(lg) {
            position: static;
        }
    }
    .top-bar {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 40px;
        @include media-breakpoint-up(md) {
            align-items: flex-end;
            justify-content: flex-end;
            position: relative;
            bottom: 20px;
            height: 1px;
        }
        @include media-breakpoint-up(lg) {
            bottom: 70px;
        }
        .search-bar{
            width: 400px;
            max-width: 100%;
            padding: 20px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            input {
                background: $c-light-beige;
                border-radius: 30px;
                padding: 10px 20px;
                margin-right: 20px;
                border: none;
                width: 300px;
                max-width: 100%;
                &::placeholder {
                    font-style: italic;
                    font-family: 'GraphikLight';
                }
                @include media-breakpoint-up(md) {

                }
            }
            button {
                border: none;
                background: none;
                padding: 10px 20px;
                color: black;
                
            }
        }
        .filter-buttons {
            width: 400px;
            max-width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            button {
                padding: 10px 20px;
                background: none;
                border: solid 2px $c-coral;
                border-radius: 30px;
                font-family: 'Optiker';
                font-size: 16px;
                line-height: 1;
                color: black;
                &:hover, &.active {
                    background-color: $c-coral;
                    color: $c-beige;
                }
                @include media-breakpoint-up(md) {
                    font-size: 18px;
                }
            }
        }
    }
}
.loading-container {
    position: static !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}