.main-menu {
    position: fixed;
    left: -218px;
    top: 350px;
    z-index: 9999;
    @include media-breakpoint-up(md) {
        left: -300px;
        top: 486px;
        position: fixed;
    }
    @media only screen 
    and (min-width : 998px) 
    and (max-width : 1224px)
    and (orientation : portrait) { 
        top: 582px;
    }
    @media only screen 
    and (min-width : 768px) 
    and (max-width : 1224px) 
    and (orientation : landscape) { 
        top: 606px;
        position: absolute;
    }
    @media only screen 
    and (min-width : 1224px) 
    and (max-width : 1599px) { 
        top: 600px;
        left: 200px;
    }
    @media only screen 
    and (min-width : 1224px) 
    and (max-width : 1599px) 
    and (orientation : landscape) { 
        left: -215px;
    }
    @media (min-width: 1600px) {
        left: 45px;
        top: 606px;
        position: absolute;
    }
    @media (min-width: 2200px) {
        left: 345px;
    }
    ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        transform: rotate(-90deg);
        li {
            list-style-type: none;
            display: block;
            margin-right: 30px;
            @media(max-width: 1024px) and (orientation: landscape) {
                margin-right: 40px;
            }
            @media(max-width: 900px) and (orientation: landscape) {
                margin-right: 60px;
            }
            a {
                font-family: 'Optiker';
                font-size: 18px;
                color: black;
                padding: 5px 0;
                &:hover, &:focus, &:active, &.active {
                    border-bottom: solid 4px $c-coral;
                    text-decoration: none;
                }
                @include media-breakpoint-up(md) {
                    font-size: 20pt;
                }
                @media(min-width: 1140px) {
                    font-size: 22pt;
                }
                @media(max-width: 1024px) and (orientation: landscape) {
                    font-size: 16pt;
                }
            }
            &.current-menu-item {
                a {
                    border-bottom: solid 4px $c-coral;
                    text-decoration: none;
                }
            }
        }
    }
}