.aMember {
    background-color: $c-light-beige;
    padding: 40px;
    border-radius: 20px;
    box-shadow: none;
    border-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    width: 320px;
    margin: auto;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
    }
    @include media-breakpoint-up(lg) {
        padding: 20px 60px;
        width: 1070px;
        max-width: 100%;
        padding: 20px 60px;
    }
    a {
        &:hover {
            text-decoration: none;
        }
    }
    &__details-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0;
        flex-grow: 1;
        height: 100%;
        width: 100%;
        text-align: center;
        @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: center;
            padding: 0 0 0 30px;
            width: auto;
            text-align: left;
        }
        @include media-breakpoint-up(lg) {
            padding: 0 0 0 40px;
            width: auto;
        }
    }
    &__left-column{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: auto;
        width: 100%;
        @include media-breakpoint-up(md) {
            align-items: flex-start;
            height: auto;
            padding-right: 20px;
        }
        @include media-breakpoint-up(lg) {
            // height: 185px; // QR code height            
        }
    }
    &__ctas-wrapper {
        padding: 20px 0;
        a {
            display: block;
            text-align: center;
            margin-bottom: 5px;
            font-size: 13px;
            line-height: 24px;
            color: black;
            font-family: 'GraphikLight';
            @include media-breakpoint-up(md) {
                text-align: left;
                font-size: 15px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 16px;
            }
            img {
                width: 22px;
                height: auto;
                margin-right: 10px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        @include media-breakpoint-up(md) {
            
        }
        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }
    &__title {
        margin-bottom: 0;
        color: black;
        font-size: 16px;
        line-height: 20px;
        font-family: 'ChronicleDisp';
        text-align: center;
        @include media-breakpoint-up(md) {
            text-align: left;
            margin-bottom: 20px;
            width: 300px;
            max-width: 70%;
            // font-size: 18px;
            // line-height: 22px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 20px;
            line-height: 24px;
            max-width: 510px;
        }
    }
    &__name {
        color: $c-coral;
        margin-bottom: 10px;
        font-size: 28px;
        line-height: 32px;
        font-family: 'Optiker';
        margin: 0;
        text-align: center;
        margin: auto;
        @include media-breakpoint-up(md) {
            text-align: left;
            font-size: 28px;
            line-height: 32px;
        }
        @include media-breakpoint-up(lg) {
            max-width: 480px;
            font-size: 35px;
            line-height: 40px;
        }
    }
    &__photo {
        width: 180px;
        height: 180px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        filter: grayscale(100%);
        margin-bottom: 20px;
        @include media-breakpoint-up(md) {
            width: 150px;
            height: 150px;
            margin: 0;
        }
        @include media-breakpoint-up(lg) {
            width: 220px;
            height: 220px;
        }
    }
    a.qrcode-link {
        margin: auto;
        @include media-breakpoint-up(md) {
            margin: 0;
        }
        img {
            width: 150px;
            height: 150px;
            @include media-breakpoint-up(md) {
                width: 120px;
                height: 120px;
            }
            @include media-breakpoint-up(lg) {
                width: 160px;
                height: 160px;
            }
        }
    }
}
br.mobile-only {
    display: block;
    @include media-breakpoint-up(md) {
        display: none;
    }
}
.member-detail-page {
    width: 100%;
    .container {
        padding: 0 20px;
    }
}
.team-member-detail-wrapper {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    padding: 0;
    @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }
    @include media-breakpoint-up(lg) {
        align-items: center;
    }
    p {
        margin: 0;
        width: 100%;
        text-align: left;
    }
    a {
        color: black;
        font-family: 'GraphikLight';
        text-decoration: none;
        text-align: center;
    }
    div.photo {
        background-position: top;
        background-repeat: no-repeat;
        background-size: contain;
        width: 220px;
        height: 220px;
        filter: grayscale(100%);
        margin-bottom: 20px;
        border-radius: 50%;
        @include media-breakpoint-up(md) {
            margin-bottom: 20px;
            background-position: top;
            width: 280px;
            height: 280px;
        }
        @include media-breakpoint-up(lg) {
            background-position: center;
            background-size: cover;
            width: 400px;
            height: 400px;
        }
    }
    div.right-column {
        padding: 0 20px;
        @include media-breakpoint-up(md){
            flex-grow: 0.8;
        }
        @include media-breakpoint-up(lg){            
            padding: 0 40px;
        }
        div.info-wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            padding-bottom: 20px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
            h1.name {
                font-size: 35px;
                line-height: 40px;
                text-align: center;
                color: $c-coral;
                font-family: 'Optiker';
                margin-bottom: 10px;
                font-weight: normal;
                @include media-breakpoint-up(md) {
                    text-align: left;
                    font-size: 35px;
                    line-height: 40px;
                    margin-bottom: 0;
                }
                @include media-breakpoint-up(lg) {
                    width: auto;
                    font-size: 45px;
                    line-height: 50px;
                }
            }
            .title {
                font-size: 18px;
                line-height: 22px;
                color: black;
                margin-bottom: 10px;
                font-family: 'ChronicleDisp';
                text-align: center;
                @include media-breakpoint-up(md) {
                    text-align: left;
                    font-size: 18px;
                    line-height: 22px;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 22px;
                    line-height: 26px;
                    margin-bottom: 10px;
                }
            }
            p {
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                @include media-breakpoint-up(md) {
                    text-align: left;
                }
            }
        }
    }
    .orange-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
        a {
            background-color: #ff5f45;
            border: 1px solid transparent;
            border-radius: 30px;
            color: #fff;
            display: block;
            font-family: 'Optiker';
            font-size: 18px;
            line-height: 22px; 
            padding: 10px 20px;
            text-align: center;
            width: 49%;
            &:hover {
                background-color: black;
            }
            @include media-breakpoint-up(lg) {
                font-size: 26px;
                line-height: 26px;
                margin-bottom: 10px;
            }
        }
    }
    .clear-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        a {
            border: 2px solid #ff5f45;
            border-radius: 30px;
            color: #000;
            font-family: Optiker,Helvetica,sans-serif;
            font-size: 26px;
            line-height: 26px;
            margin-top: 20px;
            padding: 10px 20px;
            text-align: center;
            width: 100%;
            font-size: 18px;
            line-height: 22px; 
            display: block;
            &:hover {
                background-color: black;
                color: $c-beige;
                border-color: black;
            }
            @include media-breakpoint-up(lg) {
                font-size: 26px;
                line-height: 26px;
                margin-bottom: 10px;
            }
        }
    }
}
.back-button {
    color: black;
    text-decoration: none;
    font-family: 'Gotham Book';
    font-size: 16px;
    line-height: 11px;
    text-align: center;
    display: block;
    position: absolute;
    right: 30px;
    top: 48px;
    @include media-breakpoint-up(md){
        right: 80px;
        top: 60px;
    }
    &:visited {
        color: black;
    }
    &:hover {
        text-decoration: none;
    }
    span, p {
        display: inline-block;
    }
}
.all-buttons-wrap {
    position: relative;
}
.copied {
    background-color: none;
    padding: 10px;
    opacity: 0;
    position: absolute;
    animation: myFadeIn 5s;
    font-family: 'Gotham Book';
    text-align: center !important;
    font-size: 14px;
    line-height: 1;
    bottom: -20px;
    width: 100%;
    left: 0;
    right: 0;
    color: black;
}
.map-container {
    height: 400px;
    width: 100%;
    margin-top: 20px;
    @include media-breakpoint-up(md) {
        margin-top: 40px;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 60px;
    }
}
@keyframes myFadeIn {
    0% { opacity: 0; }
    50% { opacity: 0.9 }
    100% { opacity: 0; }
}