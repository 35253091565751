.loader {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin:15px auto;
    position: relative;
    color: black;
    box-sizing: border-box;
    animation: animloader 1s linear infinite alternate;
  }
.loader p {
    width: 170px;
    position: relative;
    left: -64px;
    top: -40px;
}
  
  @keyframes animloader {
    0% {
      box-shadow: -38px -6px, -14px 6px,  14px -6px;
    }
    33% {
      box-shadow: -38px 6px, -14px -6px,  14px 6px;
    }
    66% {
      box-shadow: -38px -6px, -14px 6px, 14px -6px;
    }
    100% {
      box-shadow: -38px 6px, -14px -6px, 14px 6px;
    }
  }