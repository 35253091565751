.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 30px 20px 30px;
    flex-direction: column-reverse;
    @include media-breakpoint-up(md) {
        flex-direction: row;        
    }
    a {
        margin-right: 10px;
        font-family: 'Gotham Book';
        img {
            height: 35px;
        }
    }
    p {
        font-size: 10px;
        color: black;
        font-family: 'Gotham Book';
        @include media-breakpoint-up(md) {
            margin-right: 20px;
        }
    }
}
footer {
    padding: 40px 0;
    ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 0;
        li {
            list-style-type: none;
            margin: 8px;
            img {
                width: 30px;
                height: auto;
            }
        }
    }
}
.footer-links {
    @include media-breakpoint-up(md) {
        margin-left: 20px;
    }
    ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0;
        li {
            list-style-type: none;
            font-size: 10px;
            border-right: solid 1px grey;
            a {
                margin: 0 5px;
                color: black;
                text-decoration: none;
            }
            &:first-of-type {
                padding-right: 5px;
            }
            &:last-of-type {
                border-right: none;
            }
            
        }
    }
}