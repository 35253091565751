.listing-page {
    padding: 40px 20px 20px 20px;
    @include media-breakpoint-up(md) {
        padding: 40px 20px 20px 60px;
    }
    .view-listing-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        @include media-breakpoint-up(md) {
            flex-direction: row;
            
        }
        .view-listing-tile {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            margin-bottom: 40px;
            @include media-breakpoint-up(md) {
                width: 48%;
                margin-bottom: 0;
            }
            .background-wrapper {
                background-color: $c-light-beige;
                border-radius: 20px;
                padding: 20px;
                @include media-breakpoint-up(md) {
                    padding: 40px;
                }
                h3 {
                    color: #ff5f45;
                    font-family: Optiker,Helvetica,sans-serif;
                    font-size: 24pt;
                    line-height: 21pt;
                    margin: auto auto 30px;
                    text-align: center;
                    text-transform: uppercase;
                    width: auto;
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}