@import "fonts";
$c-gold: #D3C0AA;
$c-beige: #ede8d9;
$c-light-beige: #f7f4ea;
$c-coral: #ff5f45;

$ipad-width: calc(100% - 720px);
$desktop-width: calc(100% - 1380px);

$qrcode-ipadsize: 100px;
$qrcode-desktopsize: 150px;
$member-pading-ipad: 40px;
$member-pading-desktop: 50px;