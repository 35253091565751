.form-page {
    .form-container {
        
        flex-direction: column;
        .form-tile {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            background-color: $c-light-beige;
            border-radius: 20px;
            padding: 40px;
            margin-bottom: 40px;
            @include media-breakpoint-up(md) {
                flex-direction: row;
                align-items: center;
            }
            .form-info {
                padding-right: 20px;
                @include media-breakpoint-up(md) {
                    padding-right: 60px;
                }
                h3 {
                    color: #ff5f45;
                    font-family: Optiker,Helvetica,sans-serif;
                    font-size: 24pt;
                    line-height: 21pt;
                    margin: auto auto 30px;
                    text-align: left;
                    text-transform: uppercase;
                    width: auto;
                    span {
                        text-decoration: underline;
                    }
                }
            }
            .form-qr-code {
                img {
                    width: 200px;
                    height: auto;
                }
            }
        }
    }
}